type Environments =
  | "Development"
  | "Local"
  | "PreProduction"
  | "Production"
  | "QA";

interface AppSettings {
  CustomerSpaceUrl: string;
  Env: Environments;
  Nonce: string;
  ReservautoFrontOfficeIdentityProviderUri: string;
  RestAPISubscriptionUri: string;
}

declare global {
  interface Window {
    communautoAppSettings: AppSettings;
  }
}

const appSettings = window.communautoAppSettings;
export default appSettings;
