import UrlParams from "@reservauto/react-shared/UrlParams";

export enum TimeFormat {
  TwelveHour = 12,
  TwentyFourHour = 24,
}

class AppUrlParams {
  public readonly branchId?: number;
  public readonly isAuthenticationRetry: boolean;
  public readonly isMobileAppWebView: boolean;
  public readonly locale?: string;
  public readonly path?: string;
  public readonly timeFormat?: TimeFormat;

  public get isEmbedded(): boolean {
    return this.isMobileAppWebView;
  }

  public constructor() {
    const urlParams = new UrlParams();

    this.branchId = window.branches.getIdFromCurrentUrl();
    this.isAuthenticationRetry = urlParams.getBool("isAuthenticationRetry");
    this.isMobileAppWebView = urlParams.getBool("isMobileAppWebView");
    this.locale = urlParams.get("locale");
    this.path = urlParams.get("path");
    this.timeFormat = urlParams.getNumber("timeFormat");

    if (this.locale) {
      urlParams.delete("locale");
    }
    if (this.isAuthenticationRetry) {
      urlParams.delete("isAuthenticationRetry");
    }

    if (this.locale || this.isAuthenticationRetry) {
      urlParams.updateUrl();
    }
  }
}

const appUrlParams = new AppUrlParams();
export default appUrlParams;
