import LocalStorageUtil, {
  StorageKeyInfo,
} from "@reservauto/react-shared/localStorage/LocalStorageUtil";
import Logging from "@reservauto/react-shared/Logging";
import baseBranchStore, {
  BaseBranch,
} from "@reservauto/react-shared/stores/baseBranchStore";
import languageStore from "@reservauto/react-shared/stores/languageStore";
import StateStoreBase from "@reservauto/react-shared/stores/StateStoreBase";
import { utcToZonedTime } from "date-fns-tz";
import {
  BranchAvailableLanguageDTO,
  BranchAvailableLanguageListDTO,
  BranchSettingListDTO,
} from "../../areas/branch/dto";
import BranchSettingType from "../../areas/branch/enums/BranchSettingType";
import {
  BranchAvailableLanguagesService,
  BranchSettingsService,
} from "../../areas/branch/service";
import { EEndpointLastUpdateInfoType } from "../../areas/general/dto";
import cachedEndpoints from "../cachedEndpoints";

export interface BasicBranchInfo {
  browserTabName: string;
  countryIso2Code?: string;
  enum: string;
  id: number;
  urlPart: string;
}

export interface Branch extends BaseBranch, BasicBranchInfo {
  languages?: BranchAvailableLanguageDTO[];
}

interface BranchSettings {
  currency: string;
  timeZone: string;
}

function getLanguagesStorageKey(): StorageKeyInfo<
  BranchAvailableLanguageDTO[] | null
> {
  return {
    key: `BranchLanguages_${branchStore.get().id}`,
    pathIndependant: true,
    ttl: { days: 5 },
    userIndependant: true,
  };
}

const fallbackBranchId = 1;
const fallbackBranchSettings: BranchSettings = {
  currency: "CAD",
  timeZone: "America/Toronto",
};

export class BranchStore extends StateStoreBase<Branch> {
  private currentBranch: Branch = {
    ...window.branches.all.find((b) => b.id === fallbackBranchId)!,
    ...fallbackBranchSettings,
  };

  public correctLocale(locale: string | undefined): string {
    let twoLetterCode = locale?.toLowerCase().substring(0, 2) ?? "fr";

    const branchLanguages = this.currentBranch.languages ?? [];
    const isValidBranchLanguage = branchLanguages.some(
      (l) => l.languageIso639_1TwoLettersCode === twoLetterCode,
    );

    if (!isValidBranchLanguage) {
      Logging.warning(
        `Unknown language ${twoLetterCode} for branch ${this.currentBranch.id}`,
      );

      twoLetterCode = branchLanguages[0].languageIso639_1TwoLettersCode!;
    }

    const language = languageStore
      .getAll()
      .find((l) => l.twoLetterCode === twoLetterCode);

    if (!language) {
      Logging.warning(`Unknown language ${twoLetterCode}`);
    }

    return language ? language.localeId : "fr-ca";
  }

  public get(): Branch {
    return this.currentBranch;
  }

  public getAll(): BasicBranchInfo[] {
    return window.branches.all;
  }

  public getCurrentTimeInBranchTimeZone(): Date {
    return utcToZonedTime(
      // eslint-disable-next-line no-restricted-syntax
      new Date().getTime(),
      this.currentBranch.timeZone ?? "America/Toronto",
    );
  }

  public async populateLanguages(): Promise<void> {
    const storageKey = getLanguagesStorageKey();
    let languages = LocalStorageUtil.get(storageKey, null);

    if (!languages) {
      const result =
        await new BranchAvailableLanguagesService().getAvailableLanguage({
          branchId: this.currentBranch.id,
        });
      if (result) {
        languages = this.convertLanguages(result);
        LocalStorageUtil.set(storageKey, languages);
      }
    }

    this.currentBranch = {
      ...this.currentBranch,
      languages: languages ?? undefined,
    };
    this.notifySubscribers();
  }

  public async populateSettings(): Promise<void> {
    try {
      const result = await cachedEndpoints.getData(
        EEndpointLastUpdateInfoType.BranchSettings,
        this.currentBranch.id.toString(),
        () =>
          new BranchSettingsService().getSetting({
            branchId: this.currentBranch.id,
          }),
      );

      const settings = this.convertSettings(result);
      this.currentBranch = {
        ...this.currentBranch,
        ...settings,
      };

      baseBranchStore.populate({ ...this.currentBranch, ...settings });
    } catch (error) {
      Logging.error(error);

      this.currentBranch = {
        ...this.currentBranch,
        ...fallbackBranchSettings,
      };
    }
    this.notifySubscribers();
  }

  public setById(branchId: number): Branch {
    let branch = window.branches.all.find((b) => b.id === branchId);
    if (!branch) {
      Logging.warning(`Unknown branch id ${branchId}`);
      branch = window.branches.all.find((b) => b.id === fallbackBranchId)!;
    }

    this.currentBranch = { ...branch, ...fallbackBranchSettings };
    baseBranchStore.populate({ id: branchId, ...fallbackBranchSettings });

    this.notifySubscribers();

    return this.currentBranch;
  }

  private convertLanguages(
    languages: BranchAvailableLanguageListDTO,
  ): BranchAvailableLanguageDTO[] {
    for (const language of languages.branchLanguages!) {
      language.languageIso639_1TwoLettersCode =
        language.languageIso639_1TwoLettersCode!.toLowerCase();
    }
    return languages.branchLanguages!;
  }

  private convertSettings(settings: BranchSettingListDTO): BranchSettings {
    function findSetting(type: BranchSettingType): string | undefined {
      return (
        settings.branchSettings?.find((s) => s.branchSettingId === type)
          ?.value ?? undefined
      );
    }

    return {
      currency: findSetting(BranchSettingType.DefaultCurrency)!,
      timeZone: findSetting(BranchSettingType.Timezone)!,
    };
  }
}

const branchStore = new BranchStore();
export default branchStore;
